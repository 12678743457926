export const URL = {
    DASHBOARD_INFO: '/api/dashboard/info',
    DASHBOARD_LATEST_NOTICE_BOARDS: '/api/dashboard/latest/notice/boards',
    DASHBOARD_LATEST_QNA_BOARDS: '/api/dashboard/latest/qna/boards',

    BANNER_LIST: '/api/banners',
    BANNER_CREATE: '/api/banners/create',
    BANNER_UPDATE: '/api/banners/update',
    BANNER_DELETE: '/api/banners/delete',

    TRANSACTION_LIST: '/api/transactions',
    TRANSACTION_CREATE: '/api/transactions/create',
    TRANSACTION_UPDATE: '/api/transactions/update',
    TRANSACTION_DELETE: '/api/transactions/delete',

    PROJECT_DETAIL: '/api/project',
    PROJECT_LIST: '/api/projects',
    PROJECT_CREATE: '/api/project/create',
    PROJECT_UPDATE: '/api/project/update',
    PROJECT_DELETE: '/api/project/delete',
    PROJECT_BULK_DELETE: '/api/project/bulk/delete',

    PROJECT_ORDER_DETAIL: '/api/project/order',
    PROJECT_ORDER_LIST: '/api/project/orders',
    PROJECT_ORDER_CREATE: '/api/project/order/create',
    PROJECT_ORDER_UPDATE: '/api/project/order/update',
    PROJECT_ORDER_DELETE: '/api/project/order/delete',
    PROJECT_ORDER_BULK_DELETE: '/api/project/order/bulk/delete',

    ADMIN_LIST: '/api/security/admins',
    ADMIN_CREATE: '/api/security/admins/create',
    ADMIN_UPDATE: '/api/security/admins/update',
    ADMIN_DELETE: '/api/security/admins/delete',
    ADMIN_CHECK: '/api/security/admin/username/check',
    ADMIN_CODES_GROUP: '/api/codes/group',
    ADMIN_PASSWORD_CHANGE: '/api/change/password',
    ADMIN_ROLE: '/api/author/infolist',

    MANAGER_LIST: '/api/manager/list',

    USER_LIST: '/api/users',
    USER_CREATE: '/api/users/create',
    USER_UPDATE: '/api/users/update',
    USER_DELETE: '/api/users/delete',
    USER_CHECK: '/api/users/userIdExist',
    USER_PASSWORD_CHANGE: '/api/users/changepassword',

    CONTENT_LIST: '/api/content',
    CONTENT_CREATE: '/api/content/create',
    CONTENT_UPDATE: '/api/content/update',
    CONTENT_DELETE: '/api/content/delete',

    CONTENT_SETTINGS_LIST: '/api/content/settings',
    CONTENT_SETTINGS_CREATE: '/api/content/settings/create',
    CONTENT_SETTINGS_UPDATE: '/api/content/settings/update',
    CONTENT_SETTINGS_DELETE: '/api/content/settings/delete',
    CONTENT_SETTINGS_DETAIL: '/api/content/settings/detail',

    CONTENT_MENU_LIST: '/api/menucontents',
    CONTENT_MENU_CREATE: '/api/menucontents/create',
    CONTENT_MENU_UPDATE: '/api/menucontents/update',
    CONTENT_MENU_DELETE: '/api/menucontents/delete',
    CONTENT_MENU_DETAIL: '/api/menucontents/detail',

    POPUP_LIST: '/api/popups',
    POPUP_CREATE: '/api/popups/create',
    POPUP_UPDATE: '/api/popups/update',
    POPUP_DELETE: '/api/popups/delete',

    NOTICE_BOARD_LIST: '/api/notice/list',
    NOTICE_BOARD_DETAIL: '/api/notice/detail',
    NOTICE_BOARD_CREATE: '/api/notice/create',
    NOTICE_BOARD_UPDATE: '/api/notice/update',
    NOTICE_BOARD_DELETE: '/api/notice/delete',

    FREE_BOARD_LIST: '/api/free/board/list',
    FREE_BOARD_DETAIL: '/api/free/board/detail',
    FREE_BOARD_CREATE: '/api/free/board/create',
    FREE_BOARD_UPDATE: '/api/free/board/update',
    FREE_BOARD_DELETE: '/api/free/board/delete',

    REVIEW_BOARD_LIST: '/api/review/board/list',
    REVIEW_BOARD_DETAIL: '/api/review/board/detail',
    REVIEW_BOARD_CREATE: '/api/review/board/create',
    REVIEW_BOARD_UPDATE: '/api/review/board/update',
    REVIEW_BOARD_DELETE: '/api/review/board/delete',

    FAQ_BOARD_LIST: '/api/faq/list',
    FAQ_BOARD_DETAIL: '/api/faq/detail',
    FAQ_BOARD_CREATE: '/api/faq/create',
    FAQ_BOARD_UPDATE: '/api/faq/update',
    FAQ_BOARD_DELETE: '/api/faq/delete',

    QNA_LIST: '/api/qna/list',
    QNA_DETAIL: '/api/qna/detail',
    QNA_CREATE: '/api/qna/create',
    QNA_UPDATE: '/api/qna/update',
    QNA_DELETE: '/api/qna/delete',

    CONTACT_LIST: '/api/contact/list',
    CONTACT_DETAIL: '/api/contact/detail',
    CONTACT_CREATE: '/api/contact/create',
    CONTACT_UPDATE: '/api/contact/update',
    CONTACT_DELETE: '/api/contact/delete',

    INQUIRY_LIST: '/api/inquiry/list',
    INQUIRY_DETAIL: '/api/inquiry/detail',
    INQUIRY_CREATE: '/api/inquiry/create',
    INQUIRY_UPDATE: '/api/inquiry/update',
    INQUIRY_UPDATE_CONTACTED: '/api/inquiry/contacted',
    INQUIRY_DELETE: '/api/inquiry/bulk/delete',

    COMMUNITY_COMMENT_CREATE: '/api/board/comment/create',
    COMMUNITY_COMMENT_UPDATE: '/api/board/comment/update',
    COMMUNITY_COMMENT_DELETE: '/api/board/comment/delete/one',

    STAFF_BOARD_LIST: '/api/staff/list',
    STAFF_BOARD_DETAIL: '/api/staff/detail',
    STAFF_BOARD_CREATE: '/api/staff/create',
    STAFF_BOARD_UPDATE: '/api/staff/update',
    STAFF_BOARD_DELETE: '/api/staff/delete',

    PORTFOLIO_LIST: '/api/portfolio/list',
    PORTFOLIO_DETAIL: '/api/portfolio/detail',
    PORTFOLIO_CREATE: '/api/portfolio/create',
    PORTFOLIO_UPDATE: '/api/portfolio/update',
    PORTFOLIO_DELETE: '/api/portfolio/delete',

    CERT_BOARD_LIST: '/api/cert/list',
    CERT_BOARD_DETAIL: '/api/cert/detail',
    CERT_BOARD_CREATE: '/api/cert/create',
    CERT_BOARD_UPDATE: '/api/cert/update',
    CERT_BOARD_DELETE: '/api/cert/delete',

    GALLERY_BOARD_LIST: '/api/gallery/list',
    GALLERY_BOARD_DETAIL: '/api/gallery/detail',
    GALLERY_BOARD_CREATE: '/api/gallery/create',
    GALLERY_BOARD_UPDATE: '/api/gallery/update',
    GALLERY_BOARD_DELETE: '/api/gallery/delete',

    NEWS_BOARD_LIST: '/api/news/list',
    NEWS_BOARD_DETAIL: '/api/news/detail',
    NEWS_BOARD_CREATE: '/api/news/create',
    NEWS_BOARD_UPDATE: '/api/news/update',
    NEWS_BOARD_DELETE: '/api/news/delete',

    EVENT_BOARD_LIST: '/api/event/list',
    EVENT_BOARD_DETAIL: '/api/event/detail',
    EVENT_BOARD_CREATE: '/api/event/create',
    EVENT_BOARD_UPDATE: '/api/event/update',
    EVENT_BOARD_DELETE: '/api/event/delete',

    YOUTUBE_BOARD_LIST: '/api/youtube/list',
    YOUTUBE_BOARD_DETAIL: '/api/youtube/detail',
    YOUTUBE_BOARD_CREATE: '/api/youtube/create',
    YOUTUBE_BOARD_UPDATE: '/api/youtube/update',
    YOUTUBE_BOARD_DELETE: '/api/youtube/delete',

    SOLUTION_LIST: '/api/solution/list',
    SOLUTION_DETAIL: '/api/solution/detail',
    SOLUTION_CREATE: '/api/solution/create',
    SOLUTION_UPDATE: '/api/solution/update',
    SOLUTION_DELETE: '/api/solution/delete',

    INGREDIENT_LIST: '/api/ingredient/list',
    INGREDIENT_DETAIL: '/api/ingredient/detail',
    INGREDIENT_CREATE: '/api/ingredient/create',
    INGREDIENT_EXCEL_UPLOAD: '/api/ingredient/excel/upload',
    INGREDIENT_UPDATE: '/api/ingredient/update',
    INGREDIENT_DELETE: '/api/ingredient/delete',
    INGREDIENT_BULK_DELETE: '/api/ingredient/bulk/delete',

    ADMIN_MENU_LIST: '/api/menulist',
    MENU_ORDER_CHANGE: '/api/menu/change/order',
    USER_FILE_DELETE: '/api/files/',

    USER_MENU_LIST: '/api/menus',
    USER_MENU_CREATE: '/api/menus/create',
    USER_MENU_UPDATE: '/api/menus/update',
    USER_MENU_DELETE: '/api/menus/delete',
    COMMONCODE_LIST: '/api/codes',
    COMMONCODE_CREATE: '/api/codes/create',
    COMMONCODE_UPDATE: '/api/codes/update',
    COMMONCODE_DELETE: '/api/codes/delete',
    COMMONCODE_GROUP_LIST: '/api/group/codes',
    COMMONCODE_FILTER_LIST: '/api/codes/group',
    COMMONCODE_FILTER_BY_GROUP_LIST: '/api/codes/group/list',
    COMMONCODE_SKILL_LIST: '/api/skill/all',

    PILL_TYPE_LIST: '/api/pill/type/list',
    PILL_TYPE_DETAIL: '/api/pill/type/detail',
    PILL_TYPE_CREATE: '/api/pill/type/create',
    PILL_TYPE_UPDATE: '/api/pill/type/update',
    PILL_TYPE_DELETE: '/api/pill/type/delete',

    SITE_INFO: '/api/siteinfo',
    SITE_INFO_UPDATE: '/api/siteinfo/update',

    SYSTEM_SETTING: '/api/system/settings',
    SYSTEM_SETTING_UPDATE: '/api/system/settings/update',

    MAIL_SEND: '/api/mail/send',
    MAIL_LIST: '/api/mail/templates',
    MAIL_CREATE: '/api/mail/templates/create',
    MAIL_UPDATE: '/api/mail/templates/update',
    MAIL_DELETE: '/api/mail/templates/delete',

    BOARD_MAIL_LIST: '/api/mail/board/templates',
    BOARD_MAIL_CREATE: '/api/mail/board/templates/create',
    BOARD_MAIL_UPDATE: '/api/mail/board/templates/update',
    BOARD_MAIL_DELETE: '/api/mail/board/templates/delete',

    MAIL_GROUP_LIST: '/api/mail/history/group/list',
    MAIL_GROUP_DETAIL: '/api/mail/histories',

    ROLE_MANAGE_LIST: '/api/author/infos',
    ROLE_MANAGE_CREATE: '/api/author/infos/create',
    ROLE_MANAGE_UPDATE: '/api/author/infos/update',

    MENU_ROLE_LIST: '/api/menus/roles',
    MENU_ROLE_CREATE: '/api/author/roles/create',

    MAIL_RECEIVERS_USER: '/api/user/info/list',
    MAIL_RECEIVERS_ADMIN: '/api/security/admin/info/list',

    ACCOUNT_SETTINGS_DETAIL: '/api/security/admins',
    ACCOUNT_SETTINGS_INFO: '/api/security/admin/detail',
    ACCOUNT_SETTINGS_UPDATE: '/api/admin/updateProfileinfo',

    // MEMBERSHIP
    MEMBERSHIP_LIST: '/api/users',
    MEMBERSHIP_CREATE: '/api/users/create',
    MEMBERSHIP_UPDATE: '/api/users/update',
    MEMBERSHIP_DELETE: '/api/users/delete',

    //MAIN
    CURRENT_TIME: '/web/current/time',
    EDITOR_FILE_UPLOAD: '/api/editor/file/upload'
};
