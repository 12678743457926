import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const ProjectOrderApi = createApi({
    reducerPath: 'ProjectOrderApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['ProjectOrders'],
    endpoints: (build) => ({
        // PROJECT
        getProjectOrders: build.query({
            query: (params) => `${URL.PROJECT_ORDER_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getProjectOrder: build.query({
            query: (params) => `${URL.PROJECT_ORDER_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createProjectOrder: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_ORDER_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateProjectOrder: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_ORDER_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST','DETAIL']
        }),
        deleteProjectOrder: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_ORDER_DELETE}/${body}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST','DETAIL']
        }),
        deleteProjectOrdersBulk: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_ORDER_BULK_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST','DETAIL']
        }),

    })
});

export const {
    useGetProjectOrdersQuery,
    useGetProjectOrderQuery,
    useCreateProjectOrderMutation,
    useUpdateProjectOrderMutation,
    useDeleteProjectOrderMutation,
    useDeleteProjectOrdersBulkMutation,
} = ProjectOrderApi;
