import React, { createContext, useContext, useState } from 'react';

const PillTypeContext = createContext({ page: 0, size: 10, searchWrd: '', pillType: '', exposureYn: '' });

const PillTypeProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 10, searchWrd: '', pillType: '', exposureYn: '' });

    const value = {
        options,
        setOptions
    };

    return <PillTypeContext.Provider value={value}>{children}</PillTypeContext.Provider>;
};

const usePillTypeContext = () => {
    return useContext(PillTypeContext);
};

export { PillTypeProvider, usePillTypeContext };
