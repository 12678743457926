import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { SolutionBoardApi } from 'api/solution/SolutionBoardApi';
import { SolProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const SolutionBoardConfig = {
    routes: [
        {
            path: 'prd',
            children: [
                {
                    path: '',
                    element: (
                        <SolProvider>
                            <List />
                        </SolProvider>
                    )
                },
                {
                    path: 'create',
                    element: (
                        <SolProvider>
                            <Create />
                        </SolProvider>
                    )
                },
                {
                    path: 'modify/:code',
                    element: (
                        <SolProvider>
                            <Edit />
                        </SolProvider>
                    )
                }
            ]
        }
    ],
    API: SolutionBoardApi
};

export default SolutionBoardConfig;
