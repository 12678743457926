import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { ProjectOrderApi } from 'api/project/projectOrder';
import { ProjectOrderProvider } from './context';

const MyProjectOrders = Loadable(lazy(() => import("./list")));
const Edit = Loadable(lazy(() => import('./edit')));

const ProjectOrderConfig = {
    routes: [
        {
            path: '',
            children: [
                { path: 'list', element: <ProjectOrderProvider><MyProjectOrders /></ProjectOrderProvider> },
                { path: 'detail/:code', element: <ProjectOrderProvider><Edit /></ProjectOrderProvider> },
            ]
        }
    ],
    API: ProjectOrderApi
};

export default ProjectOrderConfig;