import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { IngredientApi } from 'api/ingredient/IngredientApi';
import { IngredientProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const IngredientConfig = {
    routes: [
        {
            path: '',
            children: [
                {
                    path: 'list',
                    element: (
                        <IngredientProvider>
                            <List />
                        </IngredientProvider>
                    )
                },
                {
                    path: 'create',
                    element: (
                        <IngredientProvider>
                            <Create />
                        </IngredientProvider>
                    )
                },
                {
                    path: 'detail/:code',
                    element: (
                        <IngredientProvider>
                            <Edit />
                        </IngredientProvider>
                    )
                }
            ]
        }
    ],
    API: IngredientApi
};

export default IngredientConfig;
