import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const SolutionBoardApi = createApi({
    reducerPath: 'SolutionBoardApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['SolutionBoard'],
    endpoints: (build) => ({
        getSolutionBoard: build.query({
            query: (params) => `${URL.SOLUTION_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getSolutionBoardDetails: build.query({
            query: (params) => `${URL.SOLUTION_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createSolutionBoard: build.mutation({
            query: (body) => ({
                url: `${URL.SOLUTION_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateSolutionBoard: build.mutation({
            query: (body) => ({
                url: `${URL.SOLUTION_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteSolutionBoard: build.mutation({
            query: (body) => ({
                url: `${URL.SOLUTION_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const {
    useGetSolutionBoardQuery,
    useGetSolutionBoardDetailsQuery,
    useCreateSolutionBoardMutation,
    useUpdateSolutionBoardMutation,
    useDeleteSolutionBoardMutation
} = SolutionBoardApi;
