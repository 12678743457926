import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { ProjectApi } from 'api/project/project';
import { ProjectProvider } from './context';

const MyProjects = Loadable(lazy(() => import("./list")));
const Edit = Loadable(lazy(() => import('./edit')));

const ProjectConfig = {
    routes: [
        {
            path: '',
            children: [
                { path: 'list', element: <ProjectProvider><MyProjects /></ProjectProvider> },
                { path: 'detail/:code', element: <ProjectProvider><Edit /></ProjectProvider> },
            ]
        }
    ],
    API: ProjectApi
};

export default ProjectConfig;