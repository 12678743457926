import { CommonCodeFilterApi } from 'api/commonCodeApi';
import { DashboardInfoApi } from 'api/dashboard/DashboardApi';
import { AuthApi } from 'views/pages/authentication/auth-forms/AuthApi';
import {
    AdminConfig,
    BannerConfig,
    CommonCodeConfig,
    ContentMenuConfig,
    FaqBoardConfig,
    PopupConfig,
    QnaConfig,
    UserMenuConfig,
    AdminMenuConfig,
    SiteInfoConfig,
    SystemSettingConfig,
    RoleManageConfig,
    MailConfig,
    SendMailConfig,
    AccountSettingsConfig,
    MembershipConfig,
    ReviewConfig,
    NoticeBoardConfig,
    InquiryBoardConfig,
    StaffBoardConfig,
    PortfolioBoardConfig,
    CertBoardConfig,
    YoutubeBoardConfig,
    GalleryBoardConfig,
    SolutionBoardConfig,
    ContactConfig,
    BoardMailConfig,
    IngredientConfig,
    PillTypeConfig,
    ProjectConfig,
    NewsBoardConfig,
    EventBoardConfig,
    ProjectOrderConfig
} from 'views';

export const Middlewares = [
    AuthApi.middleware,
    BannerConfig.API.middleware,
    AdminConfig.API.middleware,
    SiteInfoConfig.API.middleware,
    SystemSettingConfig.API.middleware,
    RoleManageConfig.API.middleware,
    PopupConfig.API.middleware,
    FaqBoardConfig.API.middleware,
    QnaConfig.API.middleware,
    UserMenuConfig.API.middleware,
    AdminMenuConfig.API.middleware,
    CommonCodeConfig.API.middleware,
    ContentMenuConfig.API.middleware,
    CommonCodeFilterApi.middleware,
    MailConfig.API.middleware,
    SendMailConfig.API.middleware,
    DashboardInfoApi.middleware,
    AccountSettingsConfig.API.middleware,
    MembershipConfig.API.middleware,
    ReviewConfig.API.middleware,
    NoticeBoardConfig.API.middleware,
    InquiryBoardConfig.API.middleware,
    StaffBoardConfig.API.middleware,
    PortfolioBoardConfig.API.middleware,
    CertBoardConfig.API.middleware,
    YoutubeBoardConfig.API.middleware,
    GalleryBoardConfig.API.middleware,
    SolutionBoardConfig.API.middleware,
    ContactConfig.API.middleware,
    BoardMailConfig.API.middleware,
    IngredientConfig.API.middleware,
    PillTypeConfig.API.middleware,
    ProjectConfig.API.middleware,
    NewsBoardConfig.API.middleware,
    EventBoardConfig.API.middleware,
    ProjectOrderConfig.API.middleware,
];
