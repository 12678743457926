import { Select as BaseSelect, FormControl, MenuItem } from '@mui/material';
import React, { forwardRef } from 'react';
import {useTranslation} from "react-i18next";

const Select = forwardRef(({ label, name, id, options, width, onChange, ...props }, ref) => {
    const { t, i18n } = useTranslation();

    return (
        <FormControl sx={{ width: width ? width : 120 }}>
            <BaseSelect
                displayEmpty
                name={props?.name}
                ref={ref}
                value={props?.value ? props?.value : ''}
                defaultValue={props?.value ? props?.value : ''}
                onChange={onChange}
                size="small"
                {...props}
            >
                <MenuItem key={'select-0'} value={''}>
                    {props?.defaulttext && props?.defaulttext || t('select')}
                </MenuItem>
                {options?.length > 0
                    ? options?.map((value, i) => (
                        <MenuItem key={i} value={value?.code}>
                            {i18n.language === 'kr' ? value.codeNm : value.codeEngNm}
                        </MenuItem>
                    )) : props.children}
            </BaseSelect>
        </FormControl>
    );
});

export default Select;
