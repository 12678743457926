import React, { createContext, useContext, useState } from 'react';

const SolContext = createContext({ page: 0, size: 10, searchWrd: '', exposureYn: '' });

const SolProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 10, searchWrd: '', exposureYn: '' });

    const value = {
        options,
        setOptions
    };

    return <SolContext.Provider value={value}>{children}</SolContext.Provider>;
};

const useSolContext = () => {
    return useContext(SolContext);
};

export { SolProvider, useSolContext };
