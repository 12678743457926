import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const PillTypeApi = createApi({
    reducerPath: 'PillTypeApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['PillTypes'],
    endpoints: (build) => ({
        getPillTypes: build.query({
            query: (params) => `${URL.PILL_TYPE_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getPillType: build.query({
            query: (params) => `${URL.PILL_TYPE_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createPillType: build.mutation({
            query: (body) => ({
                url: `${URL.PILL_TYPE_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        updatePillType: build.mutation({
            query: (body) => ({
                url: `${URL.PILL_TYPE_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deletePillType: build.mutation({
            query: (body) => ({
                url: `${URL.PILL_TYPE_DELETE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        })
    })
});

export const {
    useGetPillTypesQuery,
    useCreatePillTypeMutation,
    useUpdatePillTypeMutation,
    useDeletePillTypeMutation,
    useGetPillTypeQuery
} = PillTypeApi;
