import React, { createContext, useContext, useState } from 'react';

const ProjectContext = createContext({ page: 0, size: 10, searchWrd: '', projectStatus: '', activeYn: '', orderYn: '' });

const ProjectProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 10, searchWrd: '', projectStatus: '', activeYn: '', orderYn: '' });

    const value = {
        options,
        setOptions
    };

    return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>;
};

const useProjectContext = () => {
    return useContext(ProjectContext);
};

export { ProjectProvider, useProjectContext };
