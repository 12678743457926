import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { PillTypeApi } from 'api/common/pillType';
import { PillTypeProvider } from './context';

const List = Loadable(lazy(() => import('./list')));

const PillTypeConfig = {
    routes: [
        {
            path: 'pill',
            children: [
                {
                    path: 'types',
                    element: <PillTypeProvider><List /></PillTypeProvider>
                }
            ]
        }
    ],
    API: PillTypeApi
};

export default PillTypeConfig;
