import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const IngredientApi = createApi({
    reducerPath: 'IngredientApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['Ingredient'],
    endpoints: (build) => ({
        getIngredient: build.query({
            query: (params) => `${URL.INGREDIENT_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getIngredientDetail: build.query({
            query: (params) => `${URL.INGREDIENT_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createIngredient: build.mutation({
            query: (body) => ({
                url: `${URL.INGREDIENT_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        uploadIngredientExcel: build.mutation({
            query: (body) => ({
                url: `${URL.INGREDIENT_EXCEL_UPLOAD}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateIngredient: build.mutation({
            query: (body) => ({
                url: `${URL.INGREDIENT_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteIngredient: build.mutation({
            query: (body) => ({
                url: `${URL.INGREDIENT_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteIngredientsBulk: build.mutation({
            query: (body) => ({
                url: `${URL.INGREDIENT_BULK_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
    })
});

export const {
    useGetIngredientQuery,
    useGetIngredientDetailQuery,
    useCreateIngredientMutation,
    useUploadIngredientExcelMutation,
    useUpdateIngredientMutation,
    useDeleteIngredientMutation,
    useDeleteIngredientsBulkMutation
} = IngredientApi;
