const URL = {
    MAIN: '/',

    LOGIN: '/login', //로그인
    LOGOUT: '/logout',
    ERROR: '/error',
    FORGET_PASSWORD: '/pages/forgetpass',
    CHANGE_PASSWORD: '/web/change/password',

    //MANAGER - CMS MANAGER
    CONTENT: '/manage/content',
    CONTENT_DETAIL: '/manage/content/detail', 
    CONTENT_CREATE: '/manage/content/create', 
    CONTENT_MODIFY: '/manage/content/modify', 
    CONTENT_SETTINGS: '/manage/content/settings', 
    CONTENT_MENU: '/manage/content/menu',
    CONTENT_MENU_MODIFY: '/manage/content/menu/modify', 
    CONTENT_MENU_DETAIL: '/manage/content/menu/detail', 
    CONTENT_SETTINGS_DETAIL: '/manage/content/settings/detail', 
    CONTENT_SETTINGS_CREATE: '/manage/content/settings/create', 
    CONTENT_SETTINGS_MODIFY: '/manage/content/settings/modify', 

    CONTENT_FORM: '/manage/content/form', 
    CONTENT_FORM_DETAIL: '/manage/content/form/detail', 
    CONTENT_FORM_CREATE: '/manage/content/form/create', 
    CONTENT_FORM_MODIFY: '/manage/content/form/modify', 

    CONTENT_DATA: '/manage/content/data', 
    CONTENT_DATA_DETAIL: '/manage/content/data/detail', 
    CONTENT_DATA_CREATE: '/manage/content/data/create', 
    CONTENT_DATA_MODIFY: '/manage/content/data/modify', 

    CONTENT_BANNER: '/manage/banner',
    CONTENT_BANNER_DETAIL: '/manage/banner/detail',
    CONTENT_BANNER_CREATE: '/manage/banner/create',
    CONTENT_BANNER_MODIFY: '/manage/banner/modify',

    CONTENT_POPUP: '/manage/popup',
    CONTENT_POPUP_DETAIL: '/manage/popup/detail',
    CONTENT_POPUP_CREATE: '/manage/popup/create',
    CONTENT_POPUP_MODIFY: '/manage/popup/modify',

    BANNER: '/manage/banner',
    BANNER_DETAIL: '/manage/banner/detail',
    BANNER_MODIFY: '/manage/banner/modify',
    BANNER_CREATE: '/manage/banner/create',

    WORKER: '/manage/worker',
    WORKER_DETAIL: '/manage/worker/detail',
    WORKER_MODIFY: '/manage/worker/modify',
    WORKER_CREATE: '/manage/worker/create',

    //MEMBER
    MEMBER_ADMIN: '/member/admin',
    MEMBER_ADMIN_DETAIL: '/member/admin/detail', 
    MEMBER_ADMIN_CREATE: '/member/admin/create', 
    MEMBER_ADMIN_MODIFY: '/member/admin/modify', 

    MEMBER_USER: '/member/user', 
    MEMBER_USER_DETAIL: '/member/user/detail', 
    MEMBER_USER_CREATE: '/member/user/create', 
    MEMBER_USER_MODIFY: '/member/user/modify',

    // COMMUNITY
    COMMUNITY_NOTICE: '/community/notice',
    COMMUNITY_NOTICE_DETAIL: '/community/notice/detail',
    COMMUNITY_NOTICE_CREATE: '/community/notice/create',
    COMMUNITY_NOTICE_MODIFY: '/community/notice/modify',

    // COMMUNITY - FREE BOARD
    COMMUNITY_FREE: '/community/free',
    COMMUNITY_FREE_DETAIL: '/community/free/detail',
    COMMUNITY_FREE_CREATE: '/community/free/create',
    COMMUNITY_FREE_MODIFY: '/community/free/modify',

    COMMUNITY_REVIEW: '/community/review',
    COMMUNITY_REVIEW_DETAIL: '/community/review/detail',
    COMMUNITY_REVIEW_CREATE: '/community/review/create',
    COMMUNITY_REVIEW_MODIFY: '/community/review/modify',

    //MEMBER - ADMIN
    COMMON_CODE: '/common/code',

    COMMON_MAIL_TEMPLATE: '/common/mail',
    COMMON_MAIL_TEMPLATE_CREATE: '/common/mail/create',
    COMMON_MAIL_TEMPLATE_EDIT: '/common/mail/edit',

    COMMON_BOARD_MAIL_TEMPLATE: '/common/board/mail',
    COMMON_BOARD_MAIL_TEMPLATE_CREATE: '/common/board/mail/create',
    COMMON_BOARD_MAIL_TEMPLATE_EDIT: '/common/board/mail/edit',

    COMMON_SEND_MAIL: '/common/send',
    COMMON_SEND_MAIL_CREATE: '/common/send/create',
    COMMON_SEND_MAIL_EDIT: '/common/send/edit',
    // COMMUNITY - QNA
    COMMUNITY_QNA: '/community/qna',
    COMMUNITY_QNA_DETAIL: '/community/qna/detail',
    COMMUNITY_QNA_CREATE: '/community/qna/create',
    COMMUNITY_QNA_MODIFY: '/community/qna/modify',

    COMMUNITY_CONTACT: '/community/contact',
    COMMUNITY_CONTACT_DETAIL: '/community/contact/detail',
    COMMUNITY_CONTACT_CREATE: '/community/contact/create',
    COMMUNITY_CONTACT_MODIFY: '/community/contact/modify',

    // COMMUNITY - INQUIRY
    COMMUNITY_INQUIRY: '/community/inquiry',
    COMMUNITY_INQUIRY_DETAIL: '/community/inquiry/detail',
    COMMUNITY_INQUIRY_CREATE: '/community/inquiry/create',
    COMMUNITY_INQUIRY_MODIFY: '/community/inquiry/modify',

    COMMUNITY_PORTFOLIO: '/community/portfolio',
    COMMUNITY_PORTFOLIO_DETAIL: '/community/portfolio/detail',
    COMMUNITY_PORTFOLIO_CREATE: '/community/portfolio/create',
    COMMUNITY_PORTFOLIO_MODIFY: '/community/portfolio/modify',

    COMMUNITY_CERT: '/community/cert',
    COMMUNITY_CERT_DETAIL: '/community/cert/detail',
    COMMUNITY_CERT_CREATE: '/community/cert/create',
    COMMUNITY_CERT_MODIFY: '/community/cert/modify',

    COMMUNITY_GALLERY: '/community/gallery',
    COMMUNITY_GALLERY_DETAIL: '/community/gallery/detail',
    COMMUNITY_GALLERY_CREATE: '/community/gallery/create',
    COMMUNITY_GALLERY_MODIFY: '/community/gallery/modify',

    COMMUNITY_EVENT: '/community/event',
    COMMUNITY_EVENT_DETAIL: '/community/event/detail',
    COMMUNITY_EVENT_CREATE: '/community/event/create',
    COMMUNITY_EVENT_MODIFY: '/community/event/modify',

    COMMUNITY_NEWS: '/community/news',
    COMMUNITY_NEWS_DETAIL: '/community/news/detail',
    COMMUNITY_NEWS_CREATE: '/community/news/create',
    COMMUNITY_NEWS_MODIFY: '/community/news/modify',

    COMMUNITY_YOUTUBE: '/community/youtube',
    COMMUNITY_YOUTUBE_DETAIL: '/community/youtube/detail',
    COMMUNITY_YOUTUBE_CREATE: '/community/youtube/create',
    COMMUNITY_YOUTUBE_MODIFY: '/community/youtube/modify',

    COMMUNITY_STAFF: '/community/staff',
    COMMUNITY_STAFF_DETAIL: '/community/staff/detail',
    COMMUNITY_STAFF_CREATE: '/community/staff/create',
    COMMUNITY_STAFF_MODIFY: '/community/staff/modify',

    // COMMUNITY - FAQ
    COMMUNITY_FAQ: '/community/faq',
    COMMUNITY_FAQ_DETAIL: '/community/faq/detail',
    COMMUNITY_FAQ_CREATE: '/community/faq/create',
    COMMUNITY_FAQ_MODIFY: '/community/faq/modify',

    SOLUTION: '/solution/prd',
    SOLUTION_DETAIL: '/solution/prd/detail',
    SOLUTION_CREATE: '/solution/prd/create',
    SOLUTION_MODIFY: '/solution/prd/modify',

    INGREDIENT: '/ingredient/list',
    INGREDIENT_DETAIL: '/ingredient/detail',
    INGREDIENT_CREATE: '/ingredient/create',
    INGREDIENT_MODIFY: '/ingredient/modify',

    PROJECT: '/project/list',
    PROJECT_DETAIL: '/project/detail',
    PROJECT_CREATE: '/project/create',
    PROJECT_MODIFY: '/project/modify',

    PROJECT_ORDER: '/project/order/list',
    PROJECT_ORDER_DETAIL: '/project/order/detail',
    PROJECT_ORDER_CREATE: '/project/order/create',
    PROJECT_ORDER_MODIFY: '/project/order/modify',

    PILL_TYPE: '/community/pills',

    USER_MENU: '/user/menu',
    ADMIN_MENU: '/admin/menu',

    MANAGER: '/manager', 
    TEMPLATE: '/template', 
    TEMPLATE_DETAIL: '/template/detail',
    TEMPLATE_CREATE: '/template/create', 
    TEMPLATE_MODIFY: '/template/modify', 

    // SETTINGS
    SETTINGS_SITE_INFO: '/settings/siteinfo', 
    SETTINGS_SITE_INFO_CREATE: '/settings/siteinfo/create',
    SETTINGS_SITE_INFO_MODIFY: '/settings/siteinfo/modify',

    // SETTINGS - system setting
    SETTINGS_SYSTEM_SETTING: '/settings/systemsetting',

    // SETTINGS - role manage
    ROLE_MANAGE_SETTING: '/settings/rolemanage',

    //ACCOUNT SETTINGS
    ACCOUNT_SETTINGS: '/member/accountsettings',

    //MEMBERSHIP
    MEMBERSHIP_LIST: '/manage/membership',
    MEMBERSHIP_CREATE: '/manage/membership/create',
    MEMBERSHIP_MODIFY: '/manage/membership/modify',

};

export default URL;
