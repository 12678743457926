import React, { createContext, useContext, useState } from 'react';

const IngredientContext = createContext({ page: 0, size: 10, searchWrd: '', ingredientCategory: '', ingredientType: '' });

const IngredientProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 10, searchWrd: '', ingredientCategory: '', ingredientType: '' });

    const value = {
        options,
        setOptions
    };

    return <IngredientContext.Provider value={value}>{children}</IngredientContext.Provider>;
};

const useIngredientContext = () => {
    return useContext(IngredientContext);
};

export { IngredientProvider, useIngredientContext };
