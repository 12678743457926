import React, { createContext, useContext, useState } from 'react';

const ProjectOrderContext = createContext({ page: 0, size: 10, searchWrd: '', activeYn: '' });

const ProjectOrderProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 10, searchWrd: '', activeYn: '' });

    const value = {
        options,
        setOptions
    };

    return <ProjectOrderContext.Provider value={value}>{children}</ProjectOrderContext.Provider>;
};

const useProjectOrderContext = () => {
    return useContext(ProjectOrderContext);
};

export { ProjectOrderProvider, useProjectOrderContext };
